<template>
  <f7-popup class="report-popup" :tablet-fullscreen="false" :backdrop="true">
    <f7-page>
      <f7-navbar>
        <f7-nav-title> {{ $t.getTranslation("LBL_REPORT") }} </f7-nav-title>
        <f7-nav-right>
          <f7-link popup-close>
            <font-awesome-icon :icon="['far', 'times']" fixed-width />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <section class="report-container">
        <div class="container">
          <div class="title">
            <h1>{{ $t.getTranslation("LBL_REPORT_TITLE") }}</h1>
            <p>{{ $t.getTranslation("LBL_REPORT_SUB_CONTENT") }}</p>
          </div>

          <ul v-if="userReportTypeList && userReportTypeList.length > 0" class="report-list">
            <li v-for="userReportType in userReportTypeList" :key="'urt_' + userReportType.Code" :class="{ active: formData.ReportType == userReportType.Code }" @click="selectReportType(userReportType.Code)">{{ userReportType.Name }}</li>
          </ul>

          <f7-list id="formReport" form no-hairlines>
            <f7-list-input
              v-model:value="formData.ReportContent"
              name="ReportContent"
              :required="validationRules?.ReportContent?.required"
              :minlength="validationRules?.ReportContent?.minimumLength"
              :maxlength="validationRules?.ReportContent?.maximumLength"
              :label="$t.getTranslation('LBL_REPORT_CONTENT')"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              outline
              floating-label
              type="textarea"
              validate
              clear-button
            >
            </f7-list-input>
          </f7-list>

          <f7-button fill large preloader :loading="isButtonProcessing" :disabled="isButtonProcessing || isDisableReportButton" @click="sendReport"> {{ $t.getTranslation("LBL_REPORT") }}</f7-button>
        </div>
      </section>
    </f7-page>
  </f7-popup>
</template>

<script>
import { defineComponent, ref, reactive, inject, computed, onMounted } from "vue";
import { f7 } from "framework7-vue";
import { post } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { useStore } from "@/store";
import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";

export default defineComponent({
  name: "UserReportComponent",
  components: {},
  props: {
    type: String,
    reportKey: String,
  },
  setup(props) {
    const $t = inject("$translation");
    const store = useStore();
   
    const isButtonProcessing = ref(false);

    store.dispatch("config/fetchData", { params: { userReportTypeList: 1 } });
    const userReportTypeList = computed(() => store.getters["config/getData"]?.userReportTypeList);

    const formData = reactive({
      ReportContent: "",
      ReportType: "",
    });

    let validationRules = {};
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys];
      }
    }

    const showUserReportPopup = () => {
      f7.popup.get(".report-popup").open();
    };

    const selectReportType = (reportType) => {
      formData.ReportType = reportType;
    };

    const validate = (showNotifications) => {
      let validationRules = {};
      for (let keys in formData) {
        if (validationRulesConfig && validationRulesConfig[keys]) {
          validationRules[keys] = validationRulesConfig[keys];
        }
      }

      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      return isValid;
    };

    const isDisableReportButton = computed(() => {
      let isValid = validate(false);

      return !isValid;
    });

    const sendReport = async () => {
      let isValid = validate(true);

      if (isValid) {
        isButtonProcessing.value = true;

        let ret = await post("/userreport/save", {
          Type: props?.type,
          Key: props?.reportKey,
          ReportType: formData?.ReportType != "" ? formData?.ReportType : "",
          ReportContent: formData?.ReportContent != "" ? formData?.ReportContent : "",
        });

        isButtonProcessing.value = false;

        if (ret) {
          f7.popup.get(".report-popup").close();

          helpers.createNotification({
            type: "info",
            title: $t.getTranslation("LBL_INFO"),
            message: $t.getTranslation("LBL_INFO_SUCCESSFULLY_SEND_USER_REPORT"),
          });

          helpers.clearFormData(formData);
          helpers.resetForm("form#formReport");
        }
      }
    };

    return {
      showUserReportPopup,
      userReportTypeList,
      selectReportType,
      formData,
      validationRules,
      isButtonProcessing,
      isDisableReportButton,
      sendReport,
    };
  },
});
</script>
